var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _vm.loading != 2
                ? _c(
                    "div",
                    { staticClass: "text-center" },
                    [_c("a-spin", { attrs: { tip: "Loading..." } })],
                    1
                  )
                : _vm._e(),
              _vm.loading == 2
                ? _c(
                    "a-form-model",
                    {
                      ref: "ruleForm",
                      attrs: {
                        "label-col": _vm.labelCol,
                        "wrapper-col": _vm.wrapperCol
                      }
                    },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "所属支店" } },
                        [
                          _c("a-checkbox-group", {
                            attrs: { options: _vm.allBranches },
                            model: {
                              value: _vm.belongBranches,
                              callback: function($$v) {
                                _vm.belongBranches = $$v
                              },
                              expression: "belongBranches"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { "wrapper-col": { span: 14, offset: 4 } } },
                        [
                          _c(
                            "a-button",
                            {
                              class: _vm.$style.filledBtn,
                              attrs: { type: "primary" },
                              on: { click: _vm.onSubmit }
                            },
                            [_vm._v("更新")]
                          ),
                          _c(
                            "router-link",
                            { attrs: { to: _vm.backPath } },
                            [
                              _c(
                                "a-button",
                                { staticStyle: { "margin-left": "10px" } },
                                [
                                  _vm._v(
                                    "\n                  戻る\n                "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "utils__title mb-3" }, [
      _c("strong", { staticClass: "text-uppercase font-size-16" }, [
        _vm._v("所属支店管理")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }