<template>
  <div>
    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">所属支店管理</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="loading != 2" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <a-form-model
              v-if="loading == 2"
              ref="ruleForm"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item label="所属支店">
                <a-checkbox-group
                  v-model="belongBranches"
                  :options="allBranches"
                />
              </a-form-model-item>
              <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                <a-button type="primary" @click="onSubmit" :class="$style.filledBtn">更新</a-button>
                <router-link :to="backPath">
                  <a-button style="margin-left: 10px;">
                    戻る
                  </a-button>
                </router-link>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'

export default {
  name: 'FormUser',
  data() {
    return {
      loading: 0,
      allBranches: [],
      belongBranches: [],
      backPath: '/users/' + this.$route.params.id,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
    }
  },
  methods: {
    onSubmit() {
      const _this = this
      const send = Vue.prototype.$api.send('put', 'users_branches/' + _this.$route.params.id, { branches: this.belongBranches })
      send.then(response => {
        console.log(response)
        _this.$notification['success']({
          message: '更新しました。',
        })
        _this.$router.push(this.backPath)
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 更新に失敗しました。',
          })
        })
    },
  },
  created() {
    const resultBranchUsers = Vue.prototype.$api.send('get', 'users/' + this.$route.params.id + '/branches')
    resultBranchUsers.then(response => {
      this.loading++
      this.belongBranches = response.map((item) => { return item.id })
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 所属支店の取得に失敗しました。',
        })
        this.loading++
      })
    const resultAllBranches = Vue.prototype.$api.send('get', 'branches')
    resultAllBranches.then(response => {
      this.loading++
      this.allBranches = response.map((item) => { return { label: item.name, value: item.id } })
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 支店の取得に失敗しました。',
        })
        this.loading++
      })
  },
}

</script>
<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
</style>
